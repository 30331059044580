<script lang="ts">
import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import AppBarComponent from "~/components/AppBarComponent.vue";
import { useErrorMessageStored } from "~/store/errorMessageStored";
import { useAuthStored } from "~/store/authStored";

export default defineComponent({
  components: { AppBarComponent },
  computed: {
    ...mapState(useErrorMessageStored, [
      "messaggio",
      "titolo",
      "visibleErrorMessage"
    ])
  },
  methods: {
    ...mapActions(useErrorMessageStored, ["clearError"])
  }
});
</script>

<template>
  <v-layout class="rounded rounded-md">
    <AppBarComponent />

    <!--    <DrawerComponent :drawer="drawer" />-->

    <v-main style="min-height: 300px">
      <div class="ma-5">
        <ErrorMessage
          v-if="visibleErrorMessage"
          :text="messaggio"
          :titolo="titolo"
          @clearError="clearError"
        />
        <slot />
      </div>
    </v-main>

    <v-footer app class="d-flex align-center justify-center">
      <div>
        {{ new Date().getFullYear() }} — <strong>Dondi Salotti SRL</strong>
      </div>
    </v-footer>
  </v-layout>
</template>
